import React, { useEffect } from "react";
import "./App.css";
import { EditCategoryModal } from "./components/modals";
import AddCategoryModal from "./components/modals/AddCategoryModal";
import BlockReasonModal from "./components/modals/BlockReasonModal";
import DeleteCategoryModal from "./components/modals/DeleteCategoryModal";
import RejectMilestoneModal from "./components/modals/RejectMilestoneModal";
import RejectGrantModal from "./components/modals/RejectGrantModal";
import LoadingScreen from "./loaders/LoadingScreen";
import NotificationToast from "./loaders/NotificationToast";
import AuthRequestInterceptor from "./requests/AuthRequestInterceptor";
import RouteConfig from "./routeConfig";
import { useAppDispatch } from "./store";
import { toggleAppLoading } from "./store/slices/LoadinAndNotifSlice";
import {
  setAddCategoryModal,
  setDeleteCategoryModal,
  setEditCategoryModal,
} from "./store/slices/ModalSlice";
import {
  reconnectProviders,
  initializeProviders,
  WalletProvider,
  PROVIDER_ID,
} from "@txnlab/use-wallet";

function App() {
  const dispatch = useAppDispatch();
  const walletProviders = initializeProviders([
    PROVIDER_ID.PERA,
    PROVIDER_ID.DEFLY,
    PROVIDER_ID.WALLETCONNECT,
  ]);
  useEffect(() => {
    const payloadAddCategory = {
      addCategory: false,
      addCategoryType: "",
    };
    const payloadDeleteCategory = {
      deleteCategory: false,
      deleteCategoryId: "",
      deleteType: "",
    };
    const payloadEditCategory = {
      editCategory: false,
      editCategoryType: "",
      editCategoryName: "",
      editCategoryId: "",
      editCategorySlug: "",
    };
    dispatch(setDeleteCategoryModal(payloadDeleteCategory));
    dispatch(toggleAppLoading(false));
    dispatch(setEditCategoryModal(payloadEditCategory));
    dispatch(setAddCategoryModal(payloadAddCategory));
  }, []);
  return (
    <WalletProvider value={walletProviders}>
      <div className="App">
        <AuthRequestInterceptor />
        <RouteConfig />
        <LoadingScreen />
        <NotificationToast />
        <EditCategoryModal />
        <AddCategoryModal />
        <DeleteCategoryModal />
        <BlockReasonModal />
        <RejectMilestoneModal />
        <RejectGrantModal />
      </div>
    </WalletProvider>
  );
}

export default App;
