import algosdk from "algosdk";
import { PeraWalletConnect } from "@perawallet/connect";

const token = {
  "X-API-Key": "xZyKnhTs7T705p4Eys2qB5rrTMJDaUvO4gd4SWth",
};
const host = "https://testnet-algorand.api.purestake.io/ps2";
const port = "";
export const client = new algosdk.Algodv2(token, host, port);

export const peraWallet = new PeraWalletConnect({
  shouldShowSignTxnToast: true,
  // network: 'testnet',
  chainId: 416002,
});
